import React from 'react'

function Products() {
  return (
    <div>
      <section class="products" id="service">
        <div class="container">
          <p class="products-heading">
            Our <span>
              Products
            </span>
          </p>
          <div class="d-flex products-wrapper-main  align-items-center ">
            <div class="products-wrapper">

              <div class="features-wrapper d-flex align-items-center gap-5">
                <div>
                  <p class="key-features-text mb-0">
                    Key features:
                  </p>
                  <ul>
                    <li>
                      Operating system, Android 11

                    </li>
                    <li>
                      4G + 3G + 2G, WiFi 2.4/ 5G, BT5.O

                    </li>
                    <li>
                      5.0" HD touch screen
                    </li>
                    <li>
                      NFC + QR

                    </li>
                  </ul>
                </div>
                <div className='text-center'>
                  <img className='machine-img' src="./assets/product img.png" />
                  <p class="mobile-set">
                    M20SE
                  </p>
                </div>
              </div>
            </div>
            <div class=" pay-wrapper">

              <div class="features-wrapper d-flex align-items-center gap-5">

                <div className='text-center'>
                  <img className='machine-img' src="./assets/product img.png" />
                  <p class="mobile-set">
                  A100
                  </p>
                </div>
                <div>
                  <p class="key-features-text mb-0">
                    Key features:
                  </p>
                  <ul>

                    <li>
                      Operating system, Android 13

                    </li>
                    <li>
                      6.5" touch screen

                    </li>
                    <li>
                      Quad-core, 2.0GHz

                    </li>
                    <li>
                      2G/3G/4G, WiFi, GPS, Bluetooth 5.0

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Products